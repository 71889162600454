export const LOCAL_ENV_PUBLIC_API_URL = "http://localhost:8080/"

export enum ENV {
  DEV = "dev",
  TESTING = "testing",
  UAT = "uat",
  PRIVATE_CHAIN_PROD = "private_chain_prod"
}

type CongnitoConfig = {
  aws_project_region: string
  aws_cognito_region: string
  aws_user_pools_id: string
  aws_user_pools_web_client_id: string
  oauth: { [key in string]: string }
}

type ENV_VALUES = {
  TYPE: ENV
  PUBLIC_API_URL: string
  COGNITO_CONFIG: CongnitoConfig
  DEFAULT_COUNTRY: string
  DEFAULT_USER_EMAIL?: string
  DEFAULT_USER_PASSWORD?: string
  DEFAULT_USER_PHONE_NUMBER?: string
  VERSION?: string
}

const congnitoConfig: { [key in ENV]: CongnitoConfig } = {
  dev: {
    aws_project_region: "me-south-1",
    aws_cognito_region: "me-south-1",
    aws_user_pools_id: "me-south-1_QObKwnwY6",
    aws_user_pools_web_client_id: "743tba3tmdqtpn3ns8tnmhl1ok",
    oauth: {}
  },
  testing: {
    aws_project_region: "me-south-1",
    aws_cognito_region: "me-south-1",
    aws_user_pools_id: "me-south-1_9wTdxNUlV",
    aws_user_pools_web_client_id: "6qk31hio355uipf9qkm8ct5960",
    oauth: {}
  },
  uat: {
    aws_project_region: "me-south-1",
    aws_cognito_region: "me-south-1",
    aws_user_pools_id: "me-south-1_HEMoQQxG4",
    aws_user_pools_web_client_id: "3it8kaftu3e9nbkrhmhquqi1c5",
    oauth: {}
  },
  private_chain_prod: {
    aws_project_region: "me-south-1",
    aws_cognito_region: "me-south-1",
    aws_user_pools_id: "me-south-1_bjIhG8odw",
    aws_user_pools_web_client_id: "6anr59nb8dfj1nrjetlcuc76jn",
    oauth: {}
  }
}

const envsMap: { [key in ENV]: ENV_VALUES } = {
  dev: {
    TYPE: ENV.DEV,
    PUBLIC_API_URL: "https://api.privatechain-dev.wadzpay.com/",
    COGNITO_CONFIG: congnitoConfig.dev,
    DEFAULT_COUNTRY: "SK",
    VERSION: "1.0.0"
  },
  testing: {
    TYPE: ENV.TESTING,
    PUBLIC_API_URL: "https://api.privatechain-test.wadzpay.com/",
    COGNITO_CONFIG: congnitoConfig.testing,
    DEFAULT_COUNTRY: "IN",
    VERSION: "1.0.0"
  },
  uat: {
    TYPE: ENV.UAT,
    PUBLIC_API_URL: "https://api.privatechain-uat.wadzpay.com/",
    COGNITO_CONFIG: congnitoConfig.uat,
    DEFAULT_COUNTRY: "IN",
    VERSION: "1.0.0"
  },
  private_chain_prod: {
    TYPE: ENV.PRIVATE_CHAIN_PROD,
    PUBLIC_API_URL: "https://api.privatechain.wadzpay.com/",
    COGNITO_CONFIG: congnitoConfig.private_chain_prod,
    DEFAULT_COUNTRY: "IN",
    VERSION: "1.0.0"
  }
}

const getEnvVars = (env: string = ENV.TESTING) => {
  if (env.indexOf(ENV.TESTING) !== -1) return envsMap[ENV.TESTING]
  return envsMap[ENV.TESTING]
}

const env = getEnvVars(ENV.TESTING)

export default env
